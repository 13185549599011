export default {
	title: 'Polityka prywatności',
	full_description: `
	<p>Niniejszy dokument reguluje kwestię przetwarzania oraz ochrony danych osobowych dotyczących Użytkownik&oacute;w (os&oacute;b fizycznych), w związku z korzystaniem przez nich z serwisu: www.dnrgroup.pl, kt&oacute;rego właścicielem jest DNR GROUP sp. z o.o., Al. Rzeczpospolitej 31 lok.49, 02-972 Warszawa, NIP: 952&nbsp;219 16 76</p><br/>
	<p><strong>W szczeg&oacute;lności dbamy</strong> o ochronę przetwarzanych danych osobowych oraz stosujemy odpowiednie rozwiązania organizacyjne i techniczne, o kt&oacute;rych mowa w art. 32 Rozporządzenia Parlamentu Europejskiego i Rady 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony os&oacute;b fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE. Zastosowane rozwiązania oparte zostały o międzynarodowe normy standaryzujące systemy zarządzania bezpieczeństwem informacji, m.in. Norma PN-ISO/IEC 27001. Wdrożone procedury zapobiegają ingerencji w prywatność Użytkownik&oacute;w przez osoby trzecie. Nasze działania są ukierunkowane na zagwarantowanie Użytkownikowi poczucia pełnego bezpieczeństwa na poziomie odpowiednim do obowiązującego prawa, w tym:</p>
	<ol type="a">
	<li>
	<p>Rozporządzenie Parlamentu Europejskiego i Rady 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony os&oacute;b fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE;</p>
	</li>
	<li>
	<p>Konstytucja Rzeczypospolitej Polskiej z dnia 2 kwietnia 1997 r.;</p>
	</li>
	<li>
	<p>Ustawa z dnia 10 maja 2018 r. o ochronie danych osobowych;</p>
	</li>
	<li>
	<p>Ustawa z dnia 16 lipca 2004 r. Prawo telekomunikacyjne;</p>
	</li>
	<li>
	<p>Ustawa z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną;</p>
	</li>
	</ol>
	<p><br /></p>
	<p align="center"><strong>1. Dane osobowe os&oacute;b fizycznych (w tym os&oacute;b prowadzących jednoosobową działalność gospodarczą &ndash; postanowienia og&oacute;lne)</strong></p>
	<ol type="a">
	<ol>
	<li>
	<p>Administratorem jest DNR GROUP sp. z o.o., Al. Rzeczpospolitej 31 lok.49, 02-972 Warszawa, NIP: 952&nbsp;219 16 76, adres poczty elektronicznej: <span style="color: #0563c1;"><u><a href="mailto:biuro@dnrgroup.pl">biuro@dnrgroup.pl</a></u></span></p>
	</li>
	<li>
	<p>Administrator przetwarza dane osobowe w zakresie prowadzonej strony internetowej wyłącznie zbierając je w formularzu kontaktowym w trybie z art. 6 ust. 1 lit. a (zgoda użytkownika).</p>
	</li>
	<li>
	<p>Pani (a) dane osobowe przetwarzane będą w celu udzielenia odpowiedzi na zadane pytanie w formularzu kontaktowym. Jeśli wzajemna korespondencja doprowadzi do konieczności zmiany przesłanki przetwarzania Pani(a) danych osobowych, Administrator zobowiązuje się dopełnić szczeg&oacute;łowego informowania w trybie art. 13 ust. 1 i 2 RODO dostosowując informację do celu, w jakim dane będą pozyskiwane.</p>
	</li>
	<li>
	<p>Dane osobowe będą przetwarzane i przechowywane w formie elektronicznej, przez okres 3 miesięcy.</p>
	</li>
	<li>
	<p>Przysługuje Pani (u) prawo do cofnięcia zgody na przetwarzanie danych w dowolnym momencie. Cofnięcie zgody nie będzie miało wpływu na zgodność przetwarzania z prawem, kt&oacute;rego dokonano na podstawie zgody przed jej cofnięciem.</p>
	</li>
	<li>
	<p>Przysługuje Pani (u) prawo do żądania dostępu do danych osobowych dotyczących Pani (a) osoby, ich sprostowania, usunięcia lub ograniczenia przetwarzania oraz wniesienia sprzeciwu.</p>
	</li>
	<li>
	<p>Każda osoba, kt&oacute;rej dane dotyczą ma prawo wnieść skargę do organu nadzorczego w zgodności z art. 77 RODO.</p>
	</li>
	</ol>
	</ol>
	<p>W celu ułatwienia Państwu kontaktu z nami, na naszej stronie internetowej zamieściliśmy formularz kontaktowy. Skorzystanie z formularza wymaga podania przez Państwa swojego imienia i nazwiska, adresu e-mail, a także wyrażenia zgody na przetwarzanie przez nas Państwa danych osobowych.</p>
	<p>Kontaktując się z nami za pośrednictwem poczty elektronicznej, przekazują nam Państwo sw&oacute;j adres e-mail jako adres nadawcy wiadomości, imię i nazwisko. Ponadto, w treści wiadomości mogą Państwo zawrzeć inne dane osobowe. Podstawą prawną przetwarzania danych osobowych w takim przypadku jest Państwa zgoda.</p>
	<p>Zapewniamy, że dane osobowe są gromadzone z należytą starannością i odpowiednio chronione przed dostępem do nich przez osoby do tego nieupoważnione. Zapewniamy podjęcie wszelkich środk&oacute;w bezpieczeństwa i ochrony danych osobowych wymaganych przez przepisy o ochronie danych osobowych (RODO).</p>
	<p align="center"><strong>2. Cookie i inne technologie śledzące.</strong></p>
	<p>Nasza strona internetowa wykorzystuje pliki cookies. Cookies to niewielkie informacje tekstowe, przechowywane na Państwa urządzeniu końcowym (np. komputerze, tablecie, smartfonie), kt&oacute;re mogą być odczytywane przez nasz system teleinformatyczny.</p>
	<p>Cookies pozwalają nam:</p>
	<ul>
	<li>
	<p>zapewnić prawidłowe funkcjonowanie strony jakiego Państwo oczekują;</p>
	</li>
	<li>
	<p>poprawić szybkość i bezpieczeństwo korzystania ze strony;</p>
	</li>
	<li>
	<p>ulepszać funkcje dostępne dla Państwa na naszej stronie;</p>
	</li>
	<li>
	<p>korzystać z narzędzi analitycznych;</p>
	</li>
	<li>
	<p>korzystać z narzędzi marketingowych;</p>
	</li>
	<li>
	<p>zapewniać funkcje społecznościowe.</p>
	</li>
	</ul>
	<p>Podczas pierwszej wizyty na stronie wyświetlana jest Państwu informacja na temat stosowania plik&oacute;w cookies. Zaakceptowanie i zamknięcie tej informacji oznacza, że wyrażają Państwo zgodę na wykorzystywanie plik&oacute;w cookies zgodnie z postanowieniami niniejszej polityki prywatności. Zgodę zawsze mogą Państwo wycofać, usuwając pliki cookies oraz zmieniając ustawienia plik&oacute;w cookies w swojej przeglądarce. Proszę jednak pamiętać, że wyłączenie plik&oacute;w cookies może powodować trudności w korzystaniu ze strony, jak r&oacute;wnież z wielu innych stron internetowych, kt&oacute;re stosują cookies.</p>
	<p>Nasza strona, podobnie jak większość wsp&oacute;łczesnych stron internetowych, wykorzystuje funkcje zapewniane przez podmioty trzecie, co wiąże się z wykorzystywaniem plik&oacute;w cookies pochodzących od podmiot&oacute;w trzecich. Wykorzystanie tego rodzaju plik&oacute;w cookies zostało opisane poniżej.</p>
	<p>Wykorzystujemy pliki cookies do śledzenia statystyk strony, takich jak liczba os&oacute;b odwiedzających, rodzaj systemu operacyjnego i przeglądarki internetowej wykorzystywanej do przeglądania strony, czas spędzony na stronie, odwiedzone podstrony etc. Korzystamy w tym zakresie z narzędzia Google Analytics. Zbierane w tym zakresie informacje są całkowicie anonimowe i nie pozwalają na Państwa identyfikację. W tym celu wykorzystywane są pliki cookies firmy Google LLC dotyczące usługi Google Analytics.</p>
	<p>Korzystanie z usług Google Analytics wiąże się z zaimplementowaniem w kodzie naszej strony udostępnionego przez Google kodu śledzącego (tzw. tracking code). Kod ten bazuje na plikach cookies, ale może wykorzystywać r&oacute;wnież inne technologie śledzące.</p>
	<p>Zapewniamy możliwość korzystania z funkcji społecznościowych, takich jak udostępnianie treści w serwisach społecznościowych oraz subskrypcja profilu społecznościowego. Korzystanie z tych funkcji wiąże się z wykorzystywaniem plik&oacute;w cookies administrator&oacute;w serwis&oacute;w społecznościowych takich jak Facebook, Instagram.</p>
	<p>Korzystanie ze strony wiąże się z przesyłaniem zapytań do serwera, na kt&oacute;rym przechowywana jest strona. Każde zapytanie skierowane do serwera zapisywane jest w logach serwera.</p>
	<p>Logi obejmują m.in. Państwa adres IP, datę i czas serwera, informacje o przeglądarce internetowej i systemie operacyjnym z jakiego Państwo korzystają. Logi zapisywane i przechowywane są na serwerze.</p>
	<p>Dane zapisane w logach serwera nie są kojarzone z konkretnymi osobami korzystającymi ze serwisu i nie są wykorzystywane przez nas w celu Państwa identyfikacji.</p>
	<p>Logi serwera stanowią wyłącznie materiał pomocniczy służący do administrowania stroną a ich zawartość nie jest ujawniana nikomu poza osobami upoważnionymi do administrowania serwerem.</p>
	<p>W razie pytań związanych z zasadami ochrony danych osobowych, serdecznie zachęcamy do kontaktu mailowego bądź telefonicznego.</p>`,
};
