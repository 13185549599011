import {Link, Route, BrowserRouter as Router, Switch, useHistory} from 'react-router-dom';

import CorePageController from 'core/CorePageController';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './CoreRouting.module.scss';
import useAppConfig from 'hooks/useAppConfig';
import Home from 'temp/HomePage/index.js';
import Innovation from 'temp/InnovationsPage/index.js';
import Offer from 'temp/OfferPage/index.js';
import About from 'temp/AboutUsPage/index.js';
import Voucher from 'temp/Voucher/index';
import Faq from 'temp/FaqPage/index.js';
import Error from '../../components/Error';
import Policy from 'temp/Policy/index';
import ParticlesBg from 'components/ParticlesBg';

const cx = classNames(styles);

const CoreRouting: React.FC<CoreRoutingProps> = () => {
	// const {pages} = useAppConfig();

	return (
		<Router>
			<ParticlesBg />
			<Switch>
				<Route path='/innowacje/' exact>
					<Innovation page={'innowacje'} url={'/innowacje/'} />
				</Route>
				<Route path='/o-nas/' exact>
					<About page={'o-nas'} url={'/o-nas/'} />
				</Route>
				<Route path='/oferta/' exact>
					<Offer page={'oferta'} url={'/oferta/'} />
				</Route>
				<Route path='/oferta/:slug'>
					<Offer page={'oferta'} url={'/oferta/'} />
				</Route>
				<Route path='/faq/' exact>
					<Faq page={'faq'} url={'/faq/'} />
				</Route>
				<Route path='/polityka-prywatnosci/' exact>
					<Policy page={'polityka-prywatnosci'} url={'/polityka-prywatnosci/'} />
				</Route>
				<Route path='/voucher/' exact>
					<Voucher page={'voucher'} url={'/voucher/'} />
				</Route>
				<Route path='/' exact>
					<Home page={'home'} url={'/'} />
				</Route>

				<Route>
					<Error />
				</Route>
			</Switch>
		</Router>
	);
};

export interface CoreRoutingProps {}

export default CoreRouting;
