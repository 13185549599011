import React, {useState} from 'react';

// Headers
import logo from 'assets/dnr-logo.svg';
import user from 'assets/icon__user.svg';
import WebHeaderMenu from './ComponentHeaderMenu';
import {Link} from 'react-router-dom';

export interface IWebHeaderMainProps {
	title?: string;
}

const WebHeaderMain = (props: IWebHeaderMainProps) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<header className='section__header d-none d-xl-block'>
			<div className='web-header__container'>
				<div className='web-header__main '>
					<div className='web-header__logo'>
						<span className='d-block'>
							<Link to='/' title='' className='logo__link'>
								<img src={logo} alt='' />
							</Link>
						</span>
					</div>
					{props.title && (
						<div className='web-header__title'>
							<h2>{props.title}</h2>
						</div>
					)}
				</div>
				<div className='web-header__content'>
					<div className='web-header__user d-none'>
						<span className='text'>Panel klienta</span>
						<span className='icon'>
							<img src={user} alt='Użytkownik' />
						</span>
					</div>
					<button
						onClick={() => {
							setIsOpen(!isOpen);
						}}
						className={`web-header__burger burger ${isOpen ? 'isActive' : ''}`}
					>
						<span className='burger__span'></span>
						<span className='burger__span'></span>
						<span className='burger__span'></span>
					</button>
				</div>
			</div>
			<WebHeaderMenu isBurgerActive={isOpen} setIsBurgerActive={setIsOpen} />
		</header>
	);
};

export default WebHeaderMain;
