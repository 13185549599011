export default [
	{
		id: 0,
		title: 'ITSM Panel',
		link: '',
		short_description:
			'<p>Wdrożenie skutecznego panelu kontroli jest bardzo ważne w celu zapewnienia stałego wglądu w stan bezpieczeństwa systemów IT. Funkcjonowanie autorskiego systemu DNR Group może zostać dostosowane do potrzeb danego przedsięwzięcia.</p>',
		full_description: `<p>
									Monitoring IT to regularna i systematyczna obserwacja całego systemu
									informatycznego, które prowadzi się aby pozyskać informacje o stanie infrastruktury,
									aplikacji, stanu sprzęt, a także systemów operacyjnych. Uważna obserwacja dotyczy
									parametrów wydzielonego środowiska w ramach jednej lub wielu połączonych elementów,
									np. centrum danych, całej firmy lub samej linii produkcyjnej.
								</p>
								<br />
								<p>Wyróżniamy dwa rodzaje monitoringu IT:</p>
								<br />
								<h3>Monitoring reaktywny</h3>
								<p>
									Umożliwia kontrolę środowiska IT na bieżąco. W chwili awarii jesteśmy w stanie
									zlokalizować źródło problemu i poinformować o tym wskazane osoby. Pozwala to na
									natychmiastowe podjęcie działań mających na celu minimalizację strat wynikających z
									nieprawidłowego funkcjonowania danego systemu lub urządzenia.
								</p>
								<br />
								<h3>Monitoring proaktywny</h3>
								<p>
									Zgromadzone dane pozwalają prognozować zachowanie poszczególnych komponentów
									infrastruktury IT. To umożliwia ograniczenie do minimum możliwości występowania
									niepożądanych zdarzeń w przyszłości.{' '}
								</p>
								<p>
									Rzecz jasna w naszej ofercie znajduje się również monitoring fizyczny złożony z
									najnowocześniejszych i najlepszych rozwiązań takich jak czytnik linii papilarnych,
									czytniki kart wejścia, blokady na kod, szafy rakowe, identyfikacja osób po analizie
									twarzy, kontrola temperatury i wilgotności powietrza, czujniki dymu i gazu, a
									wszystko sterowane zdalnie 24/7.
								</p>`,
		summary_description: `<p>
									<strong>Bezpieczeństwo to podstawa!</strong> Zapewnij je swojej firmie zarówno w
									postaci monitoringu fizycznego,
									<br />
									jak&nbsp;i&nbsp;pełnego monitoringu systemów IT
								</p>`,
		subtitle: 'Dedykowany panel klienta',
		thumbnail: '/upload/image__single-innovations--1.png',
		slug: 'itsm-panel',
	},
	{
		id: 1,
		title: 'Monitoring systemów informatycznych 24/7',
		link: '',
		short_description: `<p>Zapewnienie bezpieczeństwa IT jest naszym podstawowym zadaniem. To kwestia, o którą warto zadbać od samego początku – dzięki temu ryzyko przecieków, kradzieży danych czy zmasowanych ataków będzie znacznie mniejsze.</p>`,
		full_description: `<p>
									Monitoring IT to regularna i systematyczna obserwacja całego systemu
									informatycznego, które prowadzi się aby pozyskać informacje o stanie infrastruktury,
									aplikacji, stanu sprzęt, a także systemów operacyjnych. Uważna obserwacja dotyczy
									parametrów wydzielonego środowiska w ramach jednej lub wielu połączonych elementów,
									np. centrum danych, całej firmy lub samej linii produkcyjnej.
								</p>
								<br />
								<p>Wyróżniamy dwa rodzaje monitoringu IT:</p>
								<br />
								<h3>Monitoring reaktywny</h3>
								<p>
									Umożliwia kontrolę środowiska IT na bieżąco. W chwili awarii jesteśmy w stanie
									zlokalizować źródło problemu i poinformować o tym wskazane osoby. Pozwala to na
									natychmiastowe podjęcie działań mających na celu minimalizację strat wynikających z
									nieprawidłowego funkcjonowania danego systemu lub urządzenia.
								</p>
								<br />
								<h3>Monitoring proaktywny</h3>
								<p>
									Zgromadzone dane pozwalają prognozować zachowanie poszczególnych komponentów
									infrastruktury IT. To umożliwia ograniczenie do minimum możliwości występowania
									niepożądanych zdarzeń w przyszłości.{' '}
								</p>
								<p>
									Rzecz jasna w naszej ofercie znajduje się również monitoring fizyczny złożony z
									najnowocześniejszych i najlepszych rozwiązań takich jak czytnik linii papilarnych,
									czytniki kart wejścia, blokady na kod, szafy rakowe, identyfikacja osób po analizie
									twarzy, kontrola temperatury i wilgotności powietrza, czujniki dymu i gazu, a
									wszystko sterowane zdalnie 24/7.
								</p>`,
		summary_description: `<p>
									<strong>Bezpieczeństwo to podstawa!</strong> Zapewnij je swojej firmie zarówno w
									postaci monitoringu fizycznego,
									<br />
									jak&nbsp;i&nbsp;pełnego monitoringu systemów IT
								</p>`,
		subtitle: '',
		thumbnail: '/upload/image__single-innovations--0.png',
		slug: 'monitoring-247',
	},
	{
		id: 2,
		title: 'DNR Data – Centrum Przetwarzania Danych', 
		link: '',
		short_description: `<p>Posiadanie dedykowanego centrum przetwarzania danych pozwala na wdrożenie zaawansowanych rozwiązań z dziedziny cyberbezpieczeństwa i usprawnienie procesu wymiany informacji w obrębie firmy.</p>`,
		full_description: `<p>
									Monitoring IT to regularna i systematyczna obserwacja całego systemu
									informatycznego, które prowadzi się aby pozyskać informacje o stanie infrastruktury,
									aplikacji, stanu sprzęt, a także systemów operacyjnych. Uważna obserwacja dotyczy
									parametrów wydzielonego środowiska w ramach jednej lub wielu połączonych elementów,
									np. centrum danych, całej firmy lub samej linii produkcyjnej.
								</p>
								<br />
								<p>Wyróżniamy dwa rodzaje monitoringu IT:</p>
								<br />
								<h3>Monitoring reaktywny</h3>
								<p>
									Umożliwia kontrolę środowiska IT na bieżąco. W chwili awarii jesteśmy w stanie
									zlokalizować źródło problemu i poinformować o tym wskazane osoby. Pozwala to na
									natychmiastowe podjęcie działań mających na celu minimalizację strat wynikających z
									nieprawidłowego funkcjonowania danego systemu lub urządzenia.
								</p>
								<br />
								<h3>Monitoring proaktywny</h3>
								<p>
									Zgromadzone dane pozwalają prognozować zachowanie poszczególnych komponentów
									infrastruktury IT. To umożliwia ograniczenie do minimum możliwości występowania
									niepożądanych zdarzeń w przyszłości.{' '}
								</p>
								<p>
									Rzecz jasna w naszej ofercie znajduje się również monitoring fizyczny złożony z
									najnowocześniejszych i najlepszych rozwiązań takich jak czytnik linii papilarnych,
									czytniki kart wejścia, blokady na kod, szafy rakowe, identyfikacja osób po analizie
									twarzy, kontrola temperatury i wilgotności powietrza, czujniki dymu i gazu, a
									wszystko sterowane zdalnie 24/7.
								</p>`,
		summary_description: `<p>
									<strong>Bezpieczeństwo to podstawa!</strong> Zapewnij je swojej firmie zarówno w
									postaci monitoringu fizycznego,
									<br />
									jak&nbsp;i&nbsp;pełnego monitoringu systemów IT
								</p>`,
		subtitle: '',
		thumbnail: '/upload/image__single-innovations--2.png',
		slug: 'dnr-data-–-centrum-przetwarzania-danych',
	},
];
