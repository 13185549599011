import React, {LazyExoticComponent, useRef, Suspense, lazy, useEffect, useState} from 'react';

import WebHeader from 'components/ComponentWebHeader';

import imageHeroBg from 'assets/bg__hero-about.jpg';
import 'styles/layout/SectionOurInnovationsArticle1.scss';
import 'styles/layout/SectionOurInnovationsArticle2.scss';
import 'styles/layout/SectionOurInnovationsHero.scss';

import imageInnovations2Bg from 'assets/bg__innovations-single-2.jpg';

import WebFooter from 'components/ComponentWebFooter';
import content from 'temp/content/policy';
import ReactHtmlParser from 'react-html-parser';
import ParticlesBg from 'components/ParticlesBg';

const Page = ({page, url}: {page: string; url: string}) => {
	return (
		<div>
			<WebHeader pageInfo={page} />
			<section className='section section-our-innovations-hero no-pseudo' data-section='item'>
				{/* <ParticlesBg /> */}
				<div className='section__container container__alfa color__psi no-pseudo'>
					<header className='section__header text__eta weight__bold text-center'>
						<h1>{content.title}</h1>
					</header>
					<div className='section__row row'>
						<div className='section__content px-0 col-md-10 col-xl-8 offset-md-1 offset-xl-2'>
							<div className='section__text section__policy text__zeta text-h3__beta color-h3__beta weight-h3__bold list__alfa weight__light'>
								{ReactHtmlParser(content.full_description)}
							</div>
						</div>
					</div>
				</div>
			</section>
			<WebFooter />
		</div>
	);
};

export default Page;
