import React, {LazyExoticComponent, Suspense, lazy, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import backgroundFooter from 'assets/bg__footer.jpg';
import ReactHtmlParser from 'react-html-parser';
import ParticlesBg from 'components/ParticlesBg';
import reCAPTCHA from 'helpers/recaptcha';

import 'styles/layout/SectionWebFooter.scss';

import content from 'temp/content/footer.ts';

const defaultData = {
	email: '',
	name: '',
	phone: '',
	message: '',
	agreement: false,
	agreement_value: content.agreement,
};
const WebFooter = () => {
	const [isBurgerActive, setIsBurgerActive] = useState(false);
	const [formData, setFormData] = useState(defaultData);
	const [canSend, setCanSend] = useState(true);
	const [messageSent, setMessageSent] = useState(false);
	const [recaptcha, setRecaptcha] = useState(new reCAPTCHA('6LeXZQYfAAAAAPYb_RN497EoTWr9NuWxLCF6-u9U', 'submit'));

	const handleSubmit = (e) => {
		e.preventDefault();

		if (canSend) {
			recaptcha.getToken().then((token) => {
                console.log(token);
				fetch('/api/contact/', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({...formData, recaptcha_response: token}),
				})
					.then((response) => {
						if (response.ok) {
							setFormData(defaultData);
							setCanSend(true);
							setMessageSent(true);
						}
					})
					.catch((e) => {
						setCanSend(true);
					});
			});
		}
	};

	const handleChange = (e) => {
		setFormData({...formData, [e.target.name]: e.target.value});
	};

	const handleBurgerToggle = (state, stateChanger) => {
		stateChanger(!state);
	};

	return (
		<footer className='web-footer isSingle'>
			<ParticlesBg section={true} />
			{/* <span className='web-footer__bg'>
        <img src={backgroundFooter} alt='' />
      </span> */}
			<div className='web-footer__container'>
				<header className='section__form-header text__beta weight__bold d-none d-sm-flex'>
					<h2>Napisz do nas</h2>
				</header>
				<div className='web-footer__row'>
					<div className='web-footer__col web-footer__col--form'>
						<header className='section__form-header text__beta weight__bold d-sm-none mb-3'>
							<h2>Napisz do nas</h2>
						</header>
						<form className='web-footer__form' onSubmit={handleSubmit}>
							<div className='form__default'>
								<div className='form__group'>
									<input
										id='3249-name'
										required
										type='text'
										name='name'
										className='form__control'
										placeholder='Imie'
										onChange={handleChange}
										value={formData.name}
									/>
									<label className='form__label' htmlFor='3249-name'></label>
								</div>
								<div className='form__group'>
									<input
										id='3249-email'
										required
										type='email'
										name='email'
										className='form__control'
										placeholder='Adres e-mail'
										onChange={handleChange}
										value={formData.email}
									/>
									<label className='form__label' htmlFor='3249-email'></label>
								</div>
								<div className='form__group'>
									<input
										id='3249-phone'
										type='text'
										name='phone'
										placeholder='Numer telefonu'
										className='form__control'
										onChange={handleChange}
										value={formData.phone}
									/>
									<label className='form__label' htmlFor='3249-phone'></label>
								</div>
								<div className='form__group'>
									<textarea
										id='3249-message'
										required
										name='message'
										className='form__control'
										placeholder='Wiadomość'
										onChange={handleChange}
										value={formData.message}
									></textarea>
									<label className='form__label' htmlFor='3249-phone'></label>
								</div>
								<div className='form__agreements text__zeta weight__light lh__beta'>
									<div className='form__group text__mu weight__light'>
										<input
											id='agreement-1-1639601245357'
											value={content.agreement}
											type='checkbox'
											name='agreement'
											checked={formData.agreement}
											onChange={(e) => {
												setFormData({...formData, [e.target.name]: e.target.checked});
											}}
										/>
										<label htmlFor='agreement-1-1639601245357' className='checkbox__alfa'>
											{content.agreement}
										</label>
									</div>
								</div>
							</div>
							<div className='form__actions  lh__beta'>
								<div className='form__required text__zeta weight__light'>* pole obowiązkowe</div>
								<button
									type='submit'
									className='form__button btn__beta btn-height__alfa weight__bold text__iota'
								>
									Wyślij wiadomość
								</button>
							</div>
							<div className='form__sent text-center d-flex flex-column align-items-center'>
								<div
									className='sent__title text__lambda font__beta weight__regular'
									data-gtm-vis-polling-id-36885961_33='1316'
								>
									Dziękujemy!
								</div>
								<div className='sent__text text__alfa--alternative'>
									<p>Twoja wiadomość została poprawnie wysłana</p>
								</div>
								<button
									type='button'
									data-popup='thank-you-button'
									className='sent__button btn__beta btn-height__alfa weight__bold text__iota'
								>
									Rozumiem
								</button>
							</div>
							{messageSent && (
								<div className='web-footer__thanks'>
									<span className='mb-4 color__beta text__delta weight__bold'>
										Dziękujemy za kontakt!
									</span>
									<p className='text__beta weight__bold'>Twoja wiadomość została wysłana.</p>
									<button
										className='text__beta weight__bold'
										type='button'
										onClick={() => {
											setMessageSent(false);
										}}
									>
										Zamknij
									</button>
								</div>
							)}
						</form>
					</div>
					<address className='web-footer__col web-footer__col--info'>
						<div className='web-footer__title text__delta weight__bold'>
							<h2>DNR GROUP Sp. Z o.o.</h2>
						</div>
						<div className='web-footer__content weight__regular text__kappa'>
							{ReactHtmlParser(content.full_description)}
						</div>
					</address>
				</div>
				<div className='web-footer__signature'>
                    <Link to="/polityka-prywatnosci/" className="signature__link">polityka prywatności</Link>
					<span>copyright © DNR Group sp. z o.o.</span>
				</div>
			</div>
		</footer>
	);
};

export default WebFooter;
