import React, {LazyExoticComponent, useRef, Suspense, lazy, useEffect, useState} from 'react';
import classNames from 'helpers/classNames';
import gsapHorizontal from 'components/ComponentGsapHorizontal';
import {Link} from 'react-router-dom';

// const cx = classNames(styles);

// Headers
import logo from 'assets/dnr-logo.svg';
import user from 'assets/icon__user.svg';
import WebHeader from 'components/ComponentWebHeader';

// Hero
import imageOfferSingleBg from 'assets/bg__offer-page.jpg';
import 'styles/layout/SectionFaq.scss';
import list from '../content/faq.json';
import ReactHtmlParser from 'react-html-parser';

// Footer
import WebFooter from 'components/ComponentWebFooter';

const Page = ({page, url}) => {
	const offerWrapper = useRef(null);
	const [openQuestions, setOpenQuestions] = useState([]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const toggleAction = (index) => {
		// console.log(openQuestions)
		if (openQuestions.includes(index)) {
			const newOpenQuestions = openQuestions.filter((item) => {
				return item !== index;
			});

			setOpenQuestions(newOpenQuestions);
		} else {
			let newArray = [...openQuestions];
			newArray.push(index);
			setOpenQuestions(newArray);
		}
	};
	return (
		<>
			<WebHeader pageInfo={page} />
			<section className='section section-faq' data-section='item'>
				{/* <span className='section__bg'>
					<img src={imageOfferSingleBg} alt='' />
				</span> */}
				<div className='section__container color__psi'>
					<header className='section__header text__eta weight__bold text-center'>
						<h1>Baza wiedzy</h1>
					</header>
					<div className='section__content'>
						<ul className='section__list'>
							{list.map((item, index) => (
								<li className='section__item' key={index}>
									<div
										className={`item__link text__beta weight__regular ${
											openQuestions.includes(item.id) ? 'isActive' : ''
										}`}
									>
										<button
											className='link__button text__beta weight__regular'
											onClick={() => {
												toggleAction(item.id);
											}}
										>
											<span className='item__bg'></span>
											<div className='item__top'>
												<span className='counter weight__black'>{index + 1}.</span>
												<span className='text'>{item.title}</span>
												<span className='arrow'></span>
											</div>
										</button>
										<div className='item__content'>{ReactHtmlParser(item.full_description)}</div>
									</div>
								</li>
							))}
						</ul>
					</div>
				</div>
			</section>
			<WebFooter />
		</>
	);
};

export default Page;
