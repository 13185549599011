import React from 'react';

import bgImage from 'assets/404-bg.jpg';
import error from 'assets/404.png';
import logo from 'assets/dnr-logo.svg';
import {Link} from 'react-router-dom';
import 'styles/layout/SectionError.scss';
import MetaTags from 'react-meta-tags';

const Error = () => {
	return (
		<div className='section-error'>
			<MetaTags>
				<meta name='robots' content='noindex' />
			</MetaTags>
			<span class='error__bg'>
				<img src={bgImage} />
			</span>

			<div className='container-fluid justify-content-center align-items-center'>
				<div className='row'>
					<div className='col-12 col-md-5 col-lg-4 col-404 d-flex align-items-center'>
						<figure className='error__404'>
							<img src={error} alt='404' />
						</figure>
					</div>
					<div className='col-12 col-md-7 col-lg-6 col-text d-flex align-items-center'>
						<div className='error__text'>
							<div className='text__top'>Strona o podanym adresie nie istnieje</div>
							<div className='text__bottom'>Zapraszamy na stronę główną</div>
							<Link to='/' className='text__link'>
								Strona główna
							</Link>
						</div>
					</div>
					<div className='col-12 col-lg-2 col-logo d-flex align-items-center'>
						<figure className='error__logo'>
							<img src={logo} alt='DNR Group' />
						</figure>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Error;
