import React, {useState} from 'react';

// FAQ
import imageFaqBg from 'assets/bg__faq-home.jpg';
import 'styles/layout/SectionFaqHome.scss';
import {Link} from 'react-router-dom';
import WebHeaderMain from 'components/WebHeaderMain';
import FooterMain from 'components/FooterMain';
import Scrollbars from 'react-custom-scrollbars';
import list from '../content/faq.json';
import ReactHtmlParser from 'react-html-parser';
import 'styles/layout/SectionFaq.scss';

const Faq = () => {
	const [openQuestion, setOpenQuestion] = useState<{id: number; title: string; full_description: string}>(
		list.length > 0
			? {...list[0]}
			: {
					id: -1,
					title: '',
					full_description: '',
			  }
	);
	const [width, setWidth] = useState(window.innerWidth);
	const [openQuestions, setOpenQuestions] = useState<number[]>([]);

	window.addEventListener('resize', () => {
		if (setWidth) {
			setWidth(window.innerWidth);
		}
	});

	const toggleAction = (index: number) => {
		// console.log(openQuestions)
		if (openQuestions.includes(index)) {
			const newOpenQuestions = openQuestions.filter((item) => {
				return item !== index;
			});

			setOpenQuestions(newOpenQuestions);
		} else {
			let newArray = [...openQuestions];
			newArray.push(index);
			setOpenQuestions(newArray);
		}
	};

	const toggleActionSingle = (index: number) => {
		// console.log(openQuestions)
		const items = list.filter((item) => item.id === index);

		if (items.length > 0) setOpenQuestion(items[0]);
	};

	return width >= 768 ? (
		<section className='section section-faq-home gsap-w__section' data-section-index='4' data-section='item'>
			<div className='section__container'>
				<div className='section__wrapper'>
					{/* <span className='section__bg section__bg--alfa'>
            <img src={imageFaqBg} alt='' />
          </span> */}
					<WebHeaderMain title='Baza wiedzy' />
					<div className='web-header__title d-xl-none'>
						<h2 className='text__alfa'>Baza wiedzy</h2>
					</div>
					<div className='section__content section__content--alfa'>
						<div className='section__title text__gamma text-uppercase weight__medium color__psi'>
							<h3>Najczęściej zadawane pytania</h3>
						</div>
						<div className='d-flex'>
							<nav className='section__nav'>
								<Scrollbars autoHeight autoHeightMin={window.innerHeight - 250}>
									<ul className='section__list'>
										{list.map((item, index) => (
											<li className={`list__item ${index === 0 ? 'isActive' : ''}`} key={index}>
												<button
													className={`item__link text__beta weight__regular ${
														openQuestion.id === item.id ? 'isActive' : ''
													}`}
													onClick={() => {
														toggleActionSingle(item.id);
													}}
												>
													<span className='item__border'></span>
													<span className='item__bg'></span>
													<div className='item__top'>
														<span className='counter weight__black'>{index + 1}.</span>
														<span className='text'>{item.title}</span>
														<span className='arrow'></span>
													</div>
												</button>
											</li>
										))}
									</ul>
								</Scrollbars>
							</nav>
							<div className='section__results'>
								<Scrollbars autoHeight autoHeightMin={window.innerHeight * 0.5}>
									<div>
										<div className='results__item isActive text__beta isActive'>
											<div className='results__header weight__medium'>
												<h4>{openQuestion.title}</h4>
											</div>
											<div className='results__content weight__light '>
												{ReactHtmlParser(openQuestion.full_description)}
											</div>
										</div>
									</div>
								</Scrollbars>
								<div className='results__link'>
									<div
										style={{cursor: 'pointer'}}
										onClick={() =>
											window.dispatchEvent(new CustomEvent('testEv', {detail: {path: '/faq/'}}))
										}
									>
										zobacz wszystkie pytania
									</div>
								</div>
							</div>
						</div>
					</div>
					<FooterMain />
				</div>
			</div>
		</section>
	) : (
		<section className='section section-faq' data-section='item'>
			{/* <span className='section__bg'>
        <img src={imageFaqBg} alt='' />
      </span> */}
			<div className='section__container color__psi'>
				<header className='section__header home text__eta weight__bold text-center'>
					<h2 className='text__alfa'>Baza wiedzy</h2>
				</header>
				<div className='section__content'>
					<ul className='section__list'>
						{list.map((item, index) => (
							<li className='section__item' key={index}>
								<div
									className={`item__link text__beta weight__regular ${
										openQuestions.includes(item.id) ? 'isActive' : ''
									}`}
								>
									<button
										className='link__button text__beta weight__regular'
										onClick={() => {
											toggleAction(item.id);
										}}
									>
										<span className='item__bg'></span>
										<div className='item__top'>
											<span className='counter weight__black'>{index + 1}.</span>
											<span className='text'>{item.title}</span>
											<span className='arrow'></span>
										</div>
									</button>
									<div className='item__content'>{ReactHtmlParser(item.full_description)}</div>
								</div>
							</li>
						))}
					</ul>
				</div>
			</div>
		</section>
	);
};

export default Faq;
