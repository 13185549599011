import React from 'react';

import 'styles/layout/SectionAboutArticle.scss';
import ReactHtmlParser from 'react-html-parser';

const content = {
	titile: 'Dla naszego zespołu nie ma rzeczy niemożliwych!',
	short_description: `<p>
						Nasze kompetencje potwierdzić mogą m.in. FIAT, Mazda, Nissan, KIA, K2 Asset i wielu innych
						zadowolonych klientów z różnych sektorów gospodarczych. Dołącz do nich i zapewnij swojej firmie
						obsługę IT na najwyższym poziomie!
					</p>`,
};

const AboutTeam = () => {
	return (
		<section className='section section-about-article' data-section='item'>
			<div className='section__container text-center weight__light'>
				<header className='section__header text__theta'>
					<h2>{content.titile}</h2>
				</header>
				<div className='section__text text__beta'>{ReactHtmlParser(content.short_description)}</div>
			</div>
		</section>
	);
};

export default AboutTeam;
