import React from 'react';

import Particles from 'react-tsparticles';
const config1 = {
	background: {
		image: 'linear-gradient(45deg, #1b77bd 0%, #2193cb 100%)',
		color: {
			value: '#000',
		},
		position: '50% 50%',
		repeat: 'no-repeat',
		size: 'cover',
	},
	fullScreen: {
		enable: false,
		zIndex: 5,
	},
	interactivity: {
		events: {
			onClick: {
				enable: false,
				mode: 'push',
			},
			onHover: {
				enable: false,
				mode: 'grab',
				parallax: {
					enable: false,
					force: 60,
				},
			},
		},
		modes: {
			bubble: {
				distance: 400,
				duration: 2,
				opacity: 0.2,
				size: 20,
			},
			grab: {
				distance: 400,
			},
		},
	},
	particles: {
		color: {
			value: '#f0f0f0',
		},
		links: {
			color: {
				value: '#f0f0f0',
			},
			distance: 600,
			enable: true,
			opacity: 0.15,
		},
		move: {
			attract: {
				rotate: {
					x: 900,
					y: 1900,
				},
			},
			enable: true,
		},
		number: {
			density: {
				enable: true,
				area: 3000,
				factor: 3000,
			},
		},
		opacity: {
			random: {
				enable: true,
				minimumValue: 0.1,
			},
			value: {
				min: 0.1,
				max: 0.2,
			},
			animation: {
				enable: true,
				speed: 1,
				minimumValue: 0.1,
			},
		},
		size: {
			random: {
				enable: true,
				minimumValue: 0.1,
			},
			value: {
				min: 9,
				max: 10,
			},
			animation: {
				enable: true,
				speed: 1,
				minimumValue: 0.1,
			},
		},
	},
};

const config2 = {
	background: {
		color: {
			value: '#fff',
		},
		position: '50% 50%',
		repeat: 'no-repeat',
		size: 'cover',
	},
	fullScreen: {
		enable: false,
		zIndex: 0,
	},
	interactivity: {
		events: {
			onClick: {
				enable: false,
				mode: 'push',
			},
			onHover: {
				enable: false,
				mode: 'grab',
				parallax: {
					enable: false,
					force: 60,
				},
			},
		},
		modes: {
			bubble: {
				distance: 400,
				duration: 2,
				opacity: 0.8,
				size: 30,
			},
			grab: {
				distance: 400,
			},
		},
	},
	particles: {
		color: {
			value: '#f0f0f0',
		},
		links: {
			color: {
				value: '#f0f0f0',
			},
			distance: 600,
			enable: true,
			opacity: 1,
		},
		move: {
			attract: {
				rotate: {
					x: 900,
					y: 1900,
				},
			},
			enable: true,
		},
		number: {
			density: {
				enable: true,
				area: 2500,
				factor: 2500,
			},
		},
		opacity: {
			random: {
				enable: true,
				minimumValue: 0.15,
			},
			value: {
				min: 0.4,
				max: 0.7,
			},
			animation: {
				enable: true,
				speed: 1,
				minimumValue: 0.3,
			},
		},
		size: {
			random: {
				enable: true,
				minimumValue: 0.1,
			},
			value: {
				min: 9,
				max: 10,
			},
			animation: {
				enable: true,
				speed: 1,
				minimumValue: 0.1,
			},
		},
	},
};

export interface IParticlesProps {
	section?: boolean;
}

const ParticlesBg = (props: IParticlesProps) => {
	return props.section ? (
		<div className='section-bg'>
			<Particles params={config1} id='ts2' />
		</div>
	) : (
		<div className='page-bg'>
			<Particles params={config2} />
		</div>
	);
};

export default ParticlesBg;
