import React from 'react';
import ReactHtmlParser from 'react-html-parser';

// Guarantee
import 'styles/layout/SectionGuaranteeShort.scss';
import LazyImage from 'components/LazyImage';

const list = [
	{title: 'Innowacyjne rozwiązania', thumbnail: '/upload/icon__bulb.svg'},
	{title: 'Innowacyjne rozwiązania', thumbnail: '/upload/icon__bulb.svg'},
	{title: 'Innowacyjne rozwiązania', thumbnail: '/upload/icon__bulb.svg'},
	{title: 'Innowacyjne rozwiązania', thumbnail: '/upload/icon__bulb.svg'},
];

const content = {
	title: 'Gwarantujemy',
	short_description: '<p>wsparcie i bezpieczeństwo wdrażanych rozwiązań na każdym etapie współpracy.</p>',
};

const AboutGuarantee = () => {
	return (
		<section className='section section-guarantee-short' data-section='item'>
			<div className='section__container'>
				<span className='section__bg section__bg--alfa'>
					<LazyImage src='/upload/bg__gwarancja-min.jpg' fullSrc='/upload/bg__gwarancja.jpg' />
				</span>
				<div className='section__content'>
					<header className='section__header'>
						<div className='section__title text__alfa weight__black'>
							<h2>{content.title}</h2>
						</div>
						<div className='section__subtitle spacing__alfa text__epsilon weight__light'>
							{ReactHtmlParser(content.short_description)}
						</div>
					</header>
					<div className='section__list'>
						<ul className='list__wrapper'>
							{list.map((item, index) => (
								<li className='list__item' key={index}>
									<span className='item__wrapper'>
										<figure className='item__image'>
											<img src={item.thumbnail} alt='' />
										</figure>
										<span className='item__text weight__bold text__beta'>{item.title}</span>
									</span>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AboutGuarantee;
