import React, {useEffect} from 'react';

import CoreAPI from 'core/CoreAPI/CoreAPI';
import CoreAppConfigProvider from 'core/CoreAppConfigProvider';
import CoreRouting from 'core/CoreRouting';
import 'bootstrap/scss/bootstrap-grid.scss';
import MetaTags from 'react-meta-tags';
import Loader from 'components/Loader';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
	gtmId: 'G-1YWL1DM9WZ',
};

TagManager.initialize(tagManagerArgs);

const CoreApp: React.FC<ICoreAppProps> = () => {
	return (
		<>
			<MetaTags>
				<title>DNR Group</title>
			</MetaTags>
			<Loader />
			{/* <CoreAppConfigProvider> */}
			<CoreRouting />
			{/* </CoreAppConfigProvider> */}
		</>
	);
};

export interface ICoreAppProps {}

export default CoreApp;
