import LazyImage from 'components/LazyImage';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

// Guarantee
import 'styles/layout/SectionGuarantee.scss';

const list = [
	{title: 'Innowacyjne rozwiązania', thumbnail: '/upload/icon/001-technology.svg'},
	{title: 'Rozwój technologii', thumbnail: '/upload/icon/003-cloud.svg'},
	{title: 'Szybka komunikacja', thumbnail: '/upload/icon/002-smartphone.svg'},
	{title: 'Wysoka jakość', thumbnail: '/upload/icon/004-certificate.svg'},
];

const content = {
	title: 'Gwarantujemy',
	short_description: '<p>wsparcie i bezpieczeństwo wdrażanych rozwiązań na każdym etapie współpracy.</p>',
};

const Guarantee = () => {
	return (
		<section className='section section-guarantee gsap-w__section' data-section-index='2' data-section='item'>
			<div className='section__container'>
				<div className='section__wrapper'>
					<span className='section__bg section__bg--alfa'>
						<LazyImage src='/upload/bg__gwarancja-min.jpg' fullSrc='/upload/bg__gwarancja.jpg' />
					</span>
					<div className='section__content'>
						<header className='section__header'>
							<div className='section__title text__alfa weight__black'>
								<h2>{content.title}</h2>
							</div>
							<div className='section__subtitle spacing__alfa text__epsilon weight__light'>
								{ReactHtmlParser(content.short_description)}
							</div>
						</header>
						<div className='section__list'>
							<ul className='list__wrapper'>
								{list.map((item, index) => (
									<li className='list__item' key={index}>
										<span className='item__wrapper'>
											<figure className='item__image'>
												<img src={item.thumbnail} alt='' />
											</figure>
											<span className='item__text weight__bold text__beta'>{item.title}</span>
										</span>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Guarantee;
