// import {ClassNamesFn} from 'classnames/types';
import classNames from 'classnames/bind';
import common from 'styles/helpers/cmn.module.scss';

interface CSSModule {
	[key: string]: string;
}

const classNamesHelper = (cssModule: CSSModule): any => {
	const classes = {...common, ...cssModule};
	return classNames.bind(classes);
};

export default classNamesHelper;
