import React, {LazyExoticComponent, Suspense, lazy, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import logo from 'assets/dnr-logo.svg';
import user from 'assets/icon__user.svg';

/**
 *
 * @param {{isBurgerActive: boolean, setIsBurgerActive: (any | undefined)}} param0
 * @returns
 */
const WebHeaderMenu = ({isBurgerActive, setIsBurgerActive = undefined}) => {
	const history = useHistory();

	if (history.location.pathname === '/' && window.innerWidth >= 1200)
		return (
			<nav className={`web-header__nav ${isBurgerActive ? 'isActive' : ''}`}>
				<ul className='nav__list'>
					<li className='nav__item'>
						<button
							onClick={() => {
								if (setIsBurgerActive) setIsBurgerActive(false);
							}}
							className='nav__link'
						>
							Strona główna
						</button>
					</li>
					<li className='nav__item'>
						<button
							onClick={() => window.dispatchEvent(new CustomEvent('testEv', {detail: {path: '/o-nas/'}}))}
							className='nav__link'
						>
							O nas
						</button>
					</li>
					<li className='nav__item'>
						<button
							onClick={() =>
								window.dispatchEvent(new CustomEvent('testEv', {detail: {path: '/innowacje/'}}))
							}
							className='nav__link'
						>
							Innowacje
						</button>
					</li>
					<li className='nav__item'>
						<button
							onClick={() =>
								window.dispatchEvent(new CustomEvent('testEv', {detail: {path: '/oferta/'}}))
							}
							className='nav__link'
						>
							Oferta
						</button>
					</li>
					<li className='nav__item'>
						<button
							onClick={() => window.dispatchEvent(new CustomEvent('testEv', {detail: {path: '/faq/'}}))}
							className='nav__link'
						>
							FAQ
						</button>
					</li>
				</ul>
			</nav>
		);

	return (
		<nav className={`web-header__nav ${isBurgerActive ? 'isActive' : ''}`}>
			<ul className='nav__list'>
				<li className='nav__item'>
					<Link to='/' className='nav__link'>
						Strona główna
					</Link>
				</li>
				<li className='nav__item'>
					<Link to='/o-nas/' className='nav__link'>
						O nas
					</Link>
				</li>
				<li className='nav__item'>
					<Link to='/innowacje/' className='nav__link'>
						Innowacje
					</Link>
				</li>
				<li className='nav__item'>
					<Link to='/oferta/' className='nav__link'>
						Oferta
					</Link>
				</li>
				<li className='nav__item'>
					<Link to='/faq/' className='nav__link'>
						FAQ
					</Link>
				</li>
			</ul>
		</nav>
	);
};

export default WebHeaderMenu;
