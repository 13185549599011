import React, {useEffect, useState} from 'react';
import ReactHtmlParser from 'react-html-parser';

// Footer
import 'styles/layout/SectionContact.scss';
import backgroundFooter from 'assets/bg__footer.jpg';
import content from 'temp/content/footer';
import ParticlesBg from 'components/ParticlesBg';
import reCAPTCHA from 'helpers/recaptcha';

const defaultData = {
	email: '',
	name: '',
	phone: '',
	message: '',
	agreement: false,
	agreement_value: content.agreement,
};

const Footer = () => {
	const [formData, setFormData] = useState(defaultData);
	const [canSend, setCanSend] = useState(true);
	const [messageSent, setMessageSent] = useState(false);
	const [recaptcha, setRecaptcha] = useState(new reCAPTCHA('6LeXZQYfAAAAAPYb_RN497EoTWr9NuWxLCF6-u9U', 'submit'));

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();

		if (canSend) {
			recaptcha.getToken().then((token) => {
				fetch('/api/contact/', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({...formData, recaptcha_response: token}),
				})
					.then((response) => {
						if (response.ok) {
							setFormData(defaultData);
							setCanSend(true);
							setMessageSent(true);
						}
					})
					.catch((e) => {
						setCanSend(true);
					});
			});
		}
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setFormData({...formData, [e.target.name]: e.target.value});
	};

	return (
		<section className='section section-contact gsap-w__section' data-section-index='5' data-section='item'>
			<div className='section__container'>
				<div className='section__wrapper'>
					<ParticlesBg section={true} />
					{/* <span className='section__bg section__bg--alfa'>
            <img src={backgroundFooter} alt='' />
          </span> */}
					<div className='section__content'>
						<header className='section__form-header text__beta weight__bold d-none d-sm-flex'>
							<h2>Napisz do nas</h2>
						</header>
						<div className='section__row'>
							<div className='section__col section__col--form'>
								<header className='section__form-header text__beta weight__bold d-sm-none'>
									<h2>Napisz do nas</h2>
								</header>
								<form className='section__form' onSubmit={handleSubmit}>
									<div className='form__default'>
										<div className='form__group'>
											<input
												id='3249-name'
												required
												type='text'
												name='name'
												className='form__control'
												placeholder='Imie'
												onChange={handleChange}
												value={formData.name}
											/>
											<label className='form__label' htmlFor='3249-name'></label>
										</div>
										<div className='form__group'>
											<input
												id='3249-email'
												required
												type='email'
												name='email'
												className='form__control'
												placeholder='Adres e-mail'
												onChange={handleChange}
												value={formData.email}
											/>
											<label className='form__label' htmlFor='3249-email'></label>
										</div>
										<div className='form__group'>
											<input
												id='3249-phone'
												type='text'
												name='phone'
												placeholder='Numer telefonu'
												className='form__control'
												onChange={handleChange}
												value={formData.phone}
											/>
											<label className='form__label' htmlFor='3249-phone'></label>
										</div>
										<div className='form__group'>
											<textarea
												id='3249-message'
												required
												name='message'
												className='form__control'
												placeholder='Wiadomość'
												onChange={handleChange}
												value={formData.message}
											></textarea>
											<label className='form__label' htmlFor='3249-phone'></label>
										</div>
										<div className='form__agreements text__zeta weight__light lh__beta'>
											<div className='form__group text__mu weight__light'>
												<input
													id='agreement-1-1639601245357'
													value={content.agreement}
													type='checkbox'
													name='agreement'
													checked={formData.agreement}
													onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
														setFormData({...formData, [e.target.name]: e.target.checked});
													}}
												/>
												<label htmlFor='agreement-1-1639601245357' className='checkbox__alfa'>
													{content.agreement}
												</label>
											</div>
										</div>
									</div>
									<div className='form__actions  lh__beta'>
										<div className='form__required text__zeta weight__light'>
											* pole obowiązkowe
										</div>
										<button
											type='submit'
											className='form__button btn__beta btn-height__alfa weight__bold text__iota'
										>
											Wyślij wiadomość
										</button>
									</div>
									<div className='form__sent text-center d-flex flex-column align-items-center'>
										<div
											className='sent__title text__lambda font__beta weight__regular'
											data-gtm-vis-polling-id-36885961_33='1316'
										>
											Dziękujemy!
										</div>
										<div className='sent__text text__alfa--alternative'>
											<p>Twoja wiadomość została poprawnie wysłana</p>
										</div>
										<button
											type='button'
											data-popup='thank-you-button'
											className='sent__button btn__beta btn-height__alfa weight__bold text__iota'
										>
											Rozumiem
										</button>
									</div>
									{messageSent && (
										<div className='section__thanks'>
											<span className='mb-4 color__beta text__delta weight__bold'>
												Dziękujemy za kontakt!
											</span>
											<p className='text__beta weight__bold'>Twoja wiadomość została wysłana.</p>
											<button
												className='text__beta weight__bold'
												type='button'
												onClick={() => {
													setMessageSent(false);
												}}
											>
												Zamknij
											</button>
										</div>
									)}
								</form>
							</div>
							<address className='section__col section__col--info'>
								<div className='section__title text__delta weight__bold'>
									<h2>DNR GROUP Sp. z o.o.</h2>
								</div>
								<div className='section__address weight__regular text__beta'>
									{ReactHtmlParser(content.full_description)}
								</div>
							</address>
						</div>
						<div className='section__signature d-flex'>
							<div
								style={{cursor: 'pointer'}}
								onClick={() =>
									window.dispatchEvent(
										new CustomEvent('testEv', {
											detail: {path: `/polityka-prywatnosci/`},
										})
									)
								}
								className='signature__link'
							>
								polityka prywatności
							</div>
							<span>copyright © DNR Group sp. z o.o.</span>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Footer;
