import React from 'react';
import ReactHtmlParser from 'react-html-parser';
// Hero
import 'react-jedlislider/dist/index.css';
import imageHeroBg from 'assets/bg__hero.jpg';
import 'styles/layout/SectionHero.scss';
import WebHeaderMain from 'components/WebHeaderMain';

import JedliSlider, {JedliSlide} from 'react-jedlislider';
import {Link} from 'react-router-dom';
import FooterMain from 'components/FooterMain';

import LazyImage from 'components/LazyImage.tsx';

const content = {
	title: 'Twoje IT pod kontrolą',
	short_description:
		'<p>DNR GROUP to rodzinna, nowoczesna firma stworzona przez doświadczony zespół praktyków IT. Działamy sprawnie i dynamicznie, zapewniamy aktywną współpracę z klientem oraz bardzo szybką reakcję na zgłoszenia. Słuchamy, wspieramy, reagujemy i terminowo dostarczamy wszystkie usługi. W relacjach z klientami, kierujemy się uważnością na zgłaszane problemy, jesteśmy liderami w reaktywności, a naszą niekwestionowaną siłą jest jakość. Zdobywamy zaufanie klientów gotowością na rozwiązanie trudnych zgłoszeń, zapewniamy nieprzerwany czas pracy infrastruktury IT, dzięki pełnemu zaangażowaniu naszego zespołu. Rozwijamy się dla naszych klientów. Wdrażamy standardy HR, cenimy potencjał naszych pracowników, dlatego stale podwyższamy kwalifikacje i standardy obsługi naszych klientów. Stawiamy nie tylko na nowoczesne ale i solidne i sprawdzone rozwiązania.</p>',
	link: '/o-nas/',
};

const gallery = {items: [
	'/upload/gallery/_0000_watchguard-partner.png', 
	'/upload/gallery/_0001_vmware.png', 
	'/upload/gallery/_0002_Veeam_logo_topaz_2019.png', 
	'/upload/gallery/_0003_ubuntu_black-orange_hex.png', 
	'/upload/gallery/_0004_Ubiquiti_Networks-Logo.png', 
	'/upload/gallery/_0005_sophos_logo.png', 
	'/upload/gallery/_0006_ricoh.png', 
	'/upload/gallery/_0007_QNAP.png', 
	'/upload/gallery/_0008_PfSense_logo.png', 
	'/upload/gallery/_0009_netapp-logo.png', 
	'/upload/gallery/_0010_mt.png', 
	'/upload/gallery/_0011_microsoft-logo.png', 
	'/upload/gallery/_HP_logo.png', 
	'/upload/gallery/_0012_Linux-Tux.png', 
	'/upload/gallery/_0013_lenovo-logo-transparent.png', 
	'/upload/gallery/_0014_Lenovo_Global_Corporate_Logo.png', 
	'/upload/gallery/_0015_juniper-networks-black-rgb.png', 
	'/upload/gallery/_0016_HP_logo.png', 
	'/upload/gallery/_0017_Hikvision-Logo.png', 
	'/upload/gallery/_0018_ESET-logo---Lozenge---Flat-Colour---Mid-Grey-tag---RGB.png', 
	'/upload/gallery/_0019_Eaton_Corporation_logo-700x228.png', 
	'/upload/gallery/_0020_DELLEMC.png', 
	'/upload/gallery/_0021_Dell_Technologies.png', 
	'/upload/gallery/_0022_Dell_Logo.png', 
	'/upload/gallery/_0023_comarch.png', 
	'/upload/gallery/_0024_cisco.png', 
	'/upload/gallery/_0025_Canon_logo.png', 
	'/upload/gallery/_0026_Bitdefender-Logo-BW-web.png', 
	'/upload/gallery/_0027_apc-logo.png',
	'/upload/gallery/_0028_1024px-Cisco_logo.png'
]};

const Hero = (props) => {
	const getSliderItems = (items) => {
		const slides = new Array();

		for (let i = 0; i < 4; i++) {
			items.forEach((item, index) => {
				slides.push(
					<JedliSlide key={i + index}>
						<figure className='gallery__item'>
							<img width='150' height='50' src={item} />
						</figure>
					</JedliSlide>
				);
			});
		}

		return slides;
	};

	return (
		<section className='section section-hero gsap-w__section' data-section-index='0' data-section='item'>
			<div className='section__container'>
				<div className='section__wrapper'>
					{/* <span className='section__bg section__bg--alfa'>
						<img src={imageHeroBg} alt='' />
					</span> */}
					<WebHeaderMain />
					<div className='section__content section__content--alfa'>
						<header className='section__header text__alfa weight__black'>
							<h2 className='text__alfa'>{content.title}</h2>
						</header>
						<div className='section__text text__beta weight__light'>
							{ReactHtmlParser(content.short_description)}
						</div>
						<div>
							<div
								style={{cursor: 'pointer'}}
								onClick={() =>
									window.dispatchEvent(new CustomEvent('testEv', {detail: {path: content.link}}))
								}
								className='d-block section__more weight__bold text-uppercase link__alfa text__gamma'
							>
								więcej
							</div>
						</div>
						<div className='section__gallery'>
							<div className='gallery__title text__gamma weight__light'>
								<h2>Partnerzy:</h2>
							</div>
							<div className='gallery__list'>
								{}
								<JedliSlider speed={25} slidesWidth='auto'>
									{getSliderItems(gallery.items)}
								</JedliSlider>
							</div>
						</div>
					</div>
					<FooterMain />
				</div>
				<figure className='section__image'>
					<LazyImage src='/upload/image__hero-min.jpg' fullSrc='/upload/image__hero.jpg' />
				</figure>
			</div>
		</section>
	);
};

export default Hero;
