import React, {LazyExoticComponent, useRef, Suspense, lazy, useEffect, useState} from 'react';
import classNames from 'helpers/classNames';
import gsapHorizontal from 'components/ComponentGsapHorizontal';
import {Link} from 'react-router-dom';

import WebHeader from 'components/ComponentWebHeader';

import WebFooter from 'components/ComponentWebFooter';

// Innovations
import SectionInnovationsShort from 'components/ComponentInnovationsShort';
import AboutHero from './AboutHero';
import AboutGuarantee from './AboutGuarantee';
import AboutList from './AboutList';
import AboutTeam from './AboutTeam';

const Page = ({page, url}) => {
	return (
		<div>
			<WebHeader pageInfo={page} />
			<AboutHero />
			<AboutGuarantee />
			<AboutList />
			<AboutTeam />
			<SectionInnovationsShort />
			<WebFooter />
		</div>
	);
};

export default Page;
