import React, {LazyExoticComponent, useRef, Suspense, lazy, useEffect, useState} from 'react';
import classNames from 'helpers/classNames';
import gsapHorizontal from 'components/ComponentGsapHorizontal';

import Hero from './Hero.jsx';
import Innovations from './Innovations.tsx';
import Offer from './Offer.tsx';
import Guarantee from './Guarantee.tsx';
import Faq from './Faq.tsx';
import Footer from './Footer.tsx';
import WebHeader from 'components/ComponentWebHeader';
import {useHistory} from 'react-router-dom';
import Loader from 'components/Loader.tsx';

import ParticlesBg from 'components/ParticlesBg';
const Home = ({page, url}) => {
	const scrollWrapper = useRef(null);
	const [gsapInstance, setGsapInstance] = useState(undefined);
	const history = useHistory();
	const [width, setWidth] = useState(window.innerWidth);
	const [height, setHeight] = useState(window.innerHeight);

	window.addEventListener('resize', () => {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
	});

	useEffect(() => {
		if (scrollWrapper.current) {
			setTimeout(() => {
				setGsapInstance(new gsapHorizontal(scrollWrapper.current, {autoInit: true}));
			}, 500);
		}
	}, [scrollWrapper]);

	window.addEventListener('testEv', (e) => {
		document.body.className = '';
		document.body.style.cssText = '';

		if (gsapInstance) {
			gsapInstance.instance.scrollTrigger.disable();

			if (history.location.pathname !== e.detail.path) {
				history.push(e.detail.path);
			}
		} else {
			history.push(e.detail.path);
		}
	});

	return height >= 640 && width >= 1200 ? (
		<>
			<ParticlesBg />
			<div className='page-wrapper gsap-wrapper' ref={scrollWrapper}>
				<div className='gsap-w__scroll-container' data-item='scroll-container'>
					<h1 style={{position: 'absolute', left: 0, top: 0, transform: 'translate(-100%, -100%)'}}>
						DNR Group
					</h1>

					<Hero />
					<Innovations />
					<Guarantee />
					<Offer />
					<Faq />
					<Footer />
					{/* <section
					className='section section-hero gsap-w__section gsap-w__section--test'
					data-section-index='6'
					data-section='item'
				></section> */}
				</div>
			</div>
		</>
	) : (
		<>
			{/* <ParticlesBg /> */}
			<h1 style={{position: 'absolute', left: 0, top: 0, transform: 'translate(-100%, -100%)'}}>DNR Group</h1>
			<WebHeader pageInfo={page} />
			<Hero />
			<Innovations />
			<Guarantee />
			<Offer />
			<Faq />
			<Footer />
		</>
	);
};

export default Home;
