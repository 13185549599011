import React, {useState} from 'react';

import 'styles/layout/SectionLoader.scss';

const Loader = () => {
	const [loaded, setLoaded] = useState(false);

	window.addEventListener('load', () => {
		setLoaded(true);
	});

	return <div className={`loader ${loaded ? 'hidden' : ''}`}></div>;
};

export default Loader;
