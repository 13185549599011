import React from 'react';
import {Link} from 'react-router-dom';
import WebHeaderMain from 'components/WebHeaderMain';
import imageInnovationsBg from 'assets/bg__innovations.jpg';
import 'styles/layout/SectionOffer.scss';
import FooterMain from 'components/FooterMain';
import ReactHtmlParser from 'react-html-parser';
import list from 'temp/content/offer';
import LazyImage from 'components/LazyImage';

const Offer = () => {
	return (
		<section className='section section-offer gsap-w__section' data-section-index='3' data-section='item'>
			<div className='section__container'>
				<div className='section__wrapper'>
					{/* <span className='section__bg section__bg--alfa'>
						<img src={imageInnovationsBg} alt='' />
					</span> */}
					<div className='web-header__title d-xl-none'>
						<h2 className='text__alfa'>Oferta</h2>
					</div>
					<WebHeaderMain title='Oferta' />
					<div className='section__content section__content--alfa'>
						<ul className='section__list text__beta weight__light'>
							{list.map((item, index) => (
								<li className='list__item' key={index}>
									<article className='item__wrapper'>
										<div
											onClick={() =>
												{
													if(item.slug == "dnrsoftware"){
														window.location.href = "https://dnr-software.pl/";
													} else {
														window.dispatchEvent(
															new CustomEvent('testEv', {detail: {path: `/oferta/${item.slug}`}})
														)
													}
												}
											}
											className='item__link'
										>
											<figure className='item__image'>
												<img src={item.thumbnail} style={item.slug == "dnrsoftware" ? {transform: "scale(3)"} : {}} alt='' />
											</figure>
											<div className='item__content'>
												<div>
													<header className='item__header'>
														<div className='item__title weight__bold text__beta'>
															<h3>{item.title}</h3>
														</div>
													</header>
													<div className='item__text text__zeta weight__regular'>
														{ReactHtmlParser(item.short_description)}
													</div>
												</div>
												<span className='d-block item__more  weight__bold text-uppercase link__alfa text__gamma'>
													więcej
												</span>
											</div>
										</div>
									</article>
								</li>
							))}
						</ul>
					</div>
					<FooterMain />
				</div>
				<figure className='section__image'>
					<LazyImage src='upload/image__offer-min.jpg' fullSrc='upload/image__offer.jpg' />
				</figure>
			</div>
		</section>
	);
};

export default Offer;
