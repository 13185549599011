import React from 'react';
import imageInnovationsFb from 'assets/icon__linkedin.svg';

const FooterMain = () => {
  return (
    <div className='section__social section-social__alfa'>
      <a
        href='https://www.linkedin.com/company/dnr-group/about/?viewAsMember=true'
        target='_blank'
        className='social__icon'
      >
        <img src={imageInnovationsFb} alt='' />
      </a>
    </div>
  );
};

export default FooterMain;
