import React from 'react';
import imageHeroBg from 'assets/bg__hero-about.jpg';
import 'styles/layout/SectionAboutHero.scss';
import ReactHtmlParser from 'react-html-parser';

const content = {
	titile: 'O DNR Group',
	short_description: `<p>Jesteśmy firmą oferującą kompleksowe usługi informatyczne.</p>
						<p> Naszą główną specjalizacją są działania dotyczące szerokorozumianego bezpieczeństwa IT.</p>`,
	full_description: `<p>
							Cechuje nas sprawne i dynamiczne działanie, którym zapewniamy zadowolenie nawet najbardziej
							wymagającego klienta. Słuchamy, działamy i rozwiązujemy – kierując się tymi słowami jesteśmy
							liderami w reaktywności, <br />
							a&nbsp;naszą niekwestionowaną siłą jest jakość.
						</p>`,
};

const AboutHero = () => {
	return (
		<section className='section section-about-hero' data-section='item'>
			{/* <span className='section__bg'>
				<img src={imageHeroBg} alt='' />
			</span> */}
			<div className='section__container text-center'>
				<header className='section__header text__eta weight__bold color__psi'>
					<h1>{content.titile}</h1>
				</header>
				<div className='section__content'>
					<div className='section__subtitle text__eta weight__light spacing__alfa'>
						{ReactHtmlParser(content.short_description)}
					</div>
					<div className='section__text text__beta weight__light'>
						{ReactHtmlParser(content.full_description)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default AboutHero;
