import React from 'react';
import 'styles/layout/SectionAboutList.scss';
import ReactHtmlParser from 'react-html-parser';

const list = [
	{
		full_description: `<p>
								Aby zagwarantować szybką i sprawną reakcję na zgłaszane problemy oferujemy autorski
								system zgłoszeniowy, który stworzyliśmy w&nbsp;oparciu o wieloletnie doświadczenie
								członków naszego zespołu.
							</p>`,
	},
	{
		full_description: `<p>
								Dzięki DNR Group Twoja sieć IT będzie pod stałą kontrolą najwyższej klasy specjalistów
								kompleksowo monitorujących infrastrukturę Twojej firmy, co umożliwia m. in.
								optymalizację kosztów ponoszonych w tym obszarze biznesu.
							</p>`,
	},
	{
		full_description: `<p>
								Prowadzimy również usługi doradcze, które pozwolą na sprawne wdrożenie najlepszych
								rozwiązań IT dla Twojego biznesu, a także wybranie optymalnego sprzętu
								i&nbsp;oprogramowania.
							</p>`,
	},
];

const AboutList = () => {
	return (
		<section className='section section-about-list' data-section='item'>
			<div className='section__container'>
				<ul className='section__list justify-content-center'>
					{list.map((item, index) => (
						<li className='list__item' key={index}>
							<div className='list__text text__beta weight__light'>
								{ReactHtmlParser(item.full_description)}
							</div>
						</li>
					))}
				</ul>
			</div>
		</section>
	);
};

export default AboutList;
