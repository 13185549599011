import React, {useEffect, useState} from 'react';
import {useInView} from 'react-intersection-observer';

export interface ILazyImageProps {
	src: string;
	fullSrc: string;
	alt?: string;
}

const LazyImage = ({src, fullSrc, alt}: ILazyImageProps) => {
	const [source, setSource] = useState(src);
	const [canChange, setCanChage] = useState(false);

	const {ref, inView, entry} = useInView({
		threshold: 0,
	});

	window.addEventListener('load', () => {
		setCanChage(true);
	});

	useEffect(() => {
		setCanChage(true);
	}, []);

	useEffect(() => {
		if (inView && canChange) {
			setSource(fullSrc);
		}
	}, [inView, canChange]);

	return <img ref={ref} src={source} alt={alt} />;
};

export default LazyImage;
