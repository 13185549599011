import React, {LazyExoticComponent, Suspense, lazy, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
// About Innovations

import imageInnovations0 from 'assets/image__innovation--0.png';
import imageInnovations1 from 'assets/image__innovation--1.png';
import imageAboutInnovations from 'assets/bg__about-innovations.jpg';
import 'styles/layout/SectionAboutInnovations.scss';
import list from 'temp/content/innovation';

const SectionInnovationsShort = () => {
	const [isBurgerActive, setIsBurgerActive] = useState(false);

	const handleBurgerToggle = (state, stateChanger) => {
		stateChanger(!state);
	};

	return (
		<section className='section section-about-innovations' data-section='item'>
			{/* <span className='section__bg'>
				<img src={imageAboutInnovations} alt='' />
			</span> */}
			<div className='section__container'>
				<header className='section__header text-center text__eta weight__bold color__psi'>
					<h2>Nasze innowacje</h2>
				</header>
				<ul className='section__list text__beta weight__light justify-content-center'>
					{list.map((item) => (
						<li className='list__item'>
							<article className='item__wrapper'>
								<Link to={`/innowacje/#${item.slug}`} className='item__link'>
									<figure className='item__image'>
										<img src={imageInnovations0} alt='' />
									</figure>
									<div className='item__content'>
										<div>
											<header className='item__header'>
												<div className='item__title color__psi weight__bold text__iota'>
													<h3>{item.title}</h3>
												</div>
												{item.subtitle && (
													<div className='item__subtitle weight__regular color__beta text__beta'>
														<span>{item.subtitle}</span>
													</div>
												)}
											</header>
											<div className='item__text text__zeta lh__alfa weight__regular'>
												{ReactHtmlParser(item.short_description)}
											</div>
										</div>
										<span className='d-block item__more  weight__bold text-uppercase link__alfa text__gamma'>
											więcej
										</span>
									</div>
								</Link>
							</article>
						</li>
					))}
				</ul>
			</div>
		</section>
	);
};

export default SectionInnovationsShort;
