import React, {LazyExoticComponent, Suspense, lazy, useEffect, useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import logo from 'assets/dnr-logo.svg';
import user from 'assets/icon__user.svg';
import WebHeaderMenu from 'components/ComponentHeaderMenu';
import imageLinkedin from 'assets/icon__linkedin.svg';

const WebHeader = (pageInfo) => {
	const [isBurgerActive, setIsBurgerActive] = useState(false);
	const webHeader = useRef();
	const [isHeaderScrollInit, setIsHeaderScrollInit] = useState(false);

	const handleBurgerToggle = (state, stateChanger) => {
		stateChanger(!state);
	};

	const handleHeaderSize = () => {
		// Init by default
		calculateHeaderOffset(webHeader.current);

		// And every time height of header change
		const resizeObserver = new ResizeObserver((entries) => {
			calculateHeaderOffset(entries[0].target);
		});

		resizeObserver.observe(webHeader.current);

		function calculateHeaderOffset(header) {
			// Get height of header
			let height = window.getComputedStyle(header).getPropertyValue('height');

			// Get html
			const html = document.getElementsByTagName('body')[0];

			// Add styles
			html.style.paddingTop = height;
		}
	};

	/**
	 * Init functionality to check if page is scrolled, and if so, add right class
	 */
	const handleHeaderScroll = () => {
		// Check position of scroll
		const scrollPosition = window.scrollY;

		// Check if page is far enough from top
		if (scrollPosition > 20) {
			document.body.classList.add('isPageScrolled');
		} else {
			document.body.classList.remove('isPageScrolled');
		}
	};

	useEffect(() => {
		handleHeaderSize();

		if (!isHeaderScrollInit) {
			setIsHeaderScrollInit(true);

			handleHeaderScroll();

			window.addEventListener('scroll', () => {
				handleHeaderScroll();
			});
		}
	}, [pageInfo]);

	return (
		<header className='web-header isSingle' ref={webHeader}>
			<div className='web-header__container'>
				<div className='web-header__logo'>
					<h1 className='d-block'>
						<Link to='/' title='' className='logo__link'>
							<img src={logo} alt='' />
							<span className='alt'>DNR</span>
						</Link>
					</h1>
				</div>
				<div className='web-header__content'>
					<div className='web-header__user d-none'>
						<span className='text weight__bold'>Panel klienta</span>
						<span className='icon'>
							<img src={user} alt='Użytkownik' />
						</span>
					</div>
					<button
						onClick={() => {
							setIsBurgerActive(!isBurgerActive);
						}}
						className={`web-header__burger burger ${isBurgerActive ? 'isActive' : ''}`}
					>
						<span className='burger__span'></span>
						<span className='burger__span'></span>
						<span className='burger__span'></span>
					</button>
					<div href='' className='web-header__social'>
						<a
							href='https://www.linkedin.com/company/dnr-group/about/?viewAsMember=true'
							target='_blank'
							className='social__icon'
						>
							<img src={imageLinkedin} alt='' />
						</a>
					</div>
				</div>
			</div>
			<WebHeaderMenu isBurgerActive={isBurgerActive} setIsBurgerActive={setIsBurgerActive} />
		</header>
	);
};

export default WebHeader;
