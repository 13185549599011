import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import {Scrollbars} from 'react-custom-scrollbars';

import {Link} from 'react-router-dom';
import WebHeaderMain from 'components/WebHeaderMain';

// Innovations
import imageInnovationsBg from 'assets/bg__innovations.jpg';
import 'styles/layout/SectionInnovations.scss';
import FooterMain from 'components/FooterMain';

import list from 'temp/content/innovation';

const Innovations = () => {
	return (
		<section className='section section-innovations gsap-w__section' data-section-index='1' data-section='item'>
			<div className='section__container'>
				<div className='section__wrapper'>
					{/* <span className='section__bg section__bg--alfa'>
            <img src={imageInnovationsBg} alt='' />
          </span> */}
					<WebHeaderMain title='Nasze innowacje' />
					<div className='web-header__title d-xl-none'>
						<h2 className='text__alfa'>Nasze Innowacje</h2>
					</div>
					<div className='section__content section__content--alfa'>
						{/* <Scrollbars autoHeight autoHeightMin={window.innerHeight - 200}> */}
						<ul className='section__list text__beta weight__light'>
							{list.map((item) => (
								<li className='list__item' key={item.id}>
									<article className='item__wrapper'>
										<div
											style={{cursor: 'pointer'}}
											onClick={() =>
												window.dispatchEvent(
													new CustomEvent('testEv', {
														detail: {path: `/innowacje/#${item.slug}`},
													})
												)
											}
											className='item__link'
										>
											<figure className='item__image'>
												<img src={item.thumbnail} alt='' />
											</figure>
											<div className='item__content'>
												<div>
													<header className='item__header'>
														<div className='item__title weight__bold text__delta'>
															<h3>{item.title}</h3>
														</div>
														{item.subtitle && (
															<div className='item__subtitle weight__regular color__beta text__beta'>
																<span>{item.subtitle}</span>
															</div>
														)}
													</header>
													<div className='item__text text__beta weight__regular'>
														{ReactHtmlParser(item.short_description)}
													</div>
												</div>
												<span className='d-block item__more weight__bold text-uppercase link__alfa text__gamma'>
													więcej
												</span>
											</div>
										</div>
									</article>
								</li>
							))}
						</ul>
						{/* </Scrollbars> */}
					</div>
					<FooterMain />
				</div>
			</div>
		</section>
	);
};

export default Innovations;
