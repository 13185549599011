import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

// Register scrollTriger to use horizontal scroll
gsap.registerPlugin(ScrollTrigger);

/**
 * Handle all gsap functionalities for horizontal scroll on page
 */
class gsapHorizontal {
  constructor(wrapper, options = {}) {
    this.wrapper = wrapper;
    this.instance = undefined;

    // Disable some elements on safari
    this.isMAC = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    // console.log(this.isMAC)

    if (this.isMAC) {
      document.body.classList.add('isSafari');
    }

    this.scrollContainer = this.wrapper.querySelector(`[data-item="scroll-container"]`);

    if (this.scrollContainer) {
    } else {
      console.error("ERROR! Gsap won't work. Cannot find element [data-item='scroll-container']");
    }

    this.defaultOptions = {
      // Auto init gsap
      autoInit: false,
    };

    // All important variables for future calculations
    this.info = {
      pageWidth: 0,
      fullDuration: 0,
    };

    this.options = Object.assign(this.defaultOptions, options);

    if (this.options.autoInit) {
      this.init();
    }

    window.addEventListener('resize', () => {
      if (this.instance && window.innerWidth >= 1200 && window.innerHeight >= 640) {
        try {
          this.instance.scrollTrigger.disable();
          this.instance.kill();
          this.init();
        } catch (e) {}
      } else if (this.instance) {
        try {
          this.instance.scrollTrigger.disable();
          this.instance.kill();
        } catch (e) {}
      }
    });
  }

  init() {
    // Find all sections that should be calculated
    this.allSections = this.wrapper.querySelectorAll(`[data-section="item"]`);

    // Calculate width of all sections
    this.calculateWidth();

    // Create timeline to control when which animation will begin
    // this.pageTimeline = gsap.timeline({
    //     scrollTrigger: {
    //         trigger: this.wrapper,
    //         // pin: true,
    //         pin: false,
    //         scrub: true,
    //         end: () => `${this.info.pageWidth}`
    //     }
    // })

    // Init animation on every section
    // gsap.to(this.allSections, {
    //     xPercent: -100 * (this.allSections.length - 1),
    //     ease: 'none',
    //     // ease: 'power-2.out',
    //     scrollTrigger: {
    //         trigger: this.wrapper,
    //         pin: true,
    //         scrub: 1,
    //         ease: 'none',
    //         end: () => `${this.info.fullDuration}`
    //     }
    // })

    const sectionsWrapper = this.wrapper.querySelector(`[data-item="scroll-container"]`);
    const fullDuration = this.info.fullDuration;
    const wrapper = this.wrapper;

    let scrub = 1;

    if (this.isMAC) {
      scrub = 0;
    }

    ScrollTrigger.matchMedia({
      '(orientation: landscape) and (min-height: 640px)': () => {
        this.instance = gsap.to(sectionsWrapper, {
          x: -1 * fullDuration,
          ease: 'none',
          // ease: 'power-2.out',
          scrollTrigger: {
            trigger: wrapper,
            pin: true,
            scrub: scrub,
            ease: 'none',
            end: () => `${fullDuration}`,
          },
        });
      },
    });
  }

  calculateWidth() {
    // let maxWidth = 0;
    // this.allSections.forEach(item => {
    //     maxWidth += item.offsetWidth;
    // })

    this.info.pageWidth = this.scrollContainer.offsetWidth;
    this.info.fullDuration = this.info.pageWidth - window.innerWidth;

    return this.info.pageWidth;
  }
}

export default gsapHorizontal;
