import React, {LazyExoticComponent, useRef, Suspense, lazy, useEffect, useState} from 'react';
import classNames from 'helpers/classNames';
import gsapHorizontal from 'components/ComponentGsapHorizontal';
import {Link, useHistory} from 'react-router-dom';

// const cx = classNames(styles);

// Headers
import logo from 'assets/dnr-logo.svg';
import user from 'assets/icon__user.svg';
import WebHeader from 'components/ComponentWebHeader';

import imageHeroBg from 'assets/bg__hero-about.jpg';
import 'styles/layout/SectionOurInnovationsArticle1.scss';
import 'styles/layout/SectionOurInnovationsArticle2.scss';
import 'styles/layout/SectionOurInnovationsHero.scss';

import imageInnovations0 from 'assets/image__single-innovations--0.png';
import imageInnovations1 from 'assets/image__single-innovations--1.png';
import imageInnovations2 from 'assets/image__single-innovations--2.png';

import imageInnovations2Bg from 'assets/bg__innovations-single-2.jpg';

import WebFooter from 'components/ComponentWebFooter';
import list from 'temp/content/innovation.ts';
import ReactHtmlParser from 'react-html-parser';

const Page = ({page, url}) => {
	const history = useHistory();
	const section1 = useRef(null);
	const section2 = useRef(null);
	const section3 = useRef(null);

	useEffect(() => {
		window.scrollTo(0, 0);

		setTimeout(() => {
			console.log(history.location.hash);
			if (history.location.hash) {
				if (history.location.hash.replace('#', '') === section1.current.id) {
					// section1.current.className += ' scroll';
					// section1.current.scrollIntoView({behavior: 'smooth', block: 'start'});
				} else if (history.location.hash.replace('#', '') === section2.current.id) {
					section2.current.className += ' scroll';
					section2.current.scrollIntoView({behavior: 'smooth', block: 'start'});
				} else if (history.location.hash.replace('#', '') === section3.current.id) {
					section3.current.className += ' scroll';
					section3.current.scrollIntoView({behavior: 'smooth', block: 'start'});
				}
			}
		}, 500);
	}, []);

	return (
		<div>
			<WebHeader pageInfo={page} />
			<section
				className='section section-our-innovations-hero'
				data-section='item'
				ref={section1}
				id={list[0].slug}
			>
				{/* <span className='section__bg'>
					<img src={imageHeroBg} alt='' />
				</span> */}
				<div className='section__container container__alfa color__psi'>
					<header className='section__header text__eta weight__bold text-center'>
						<h1>Nasze innowacje</h1>
					</header>
					<div className='section__row row'>
						<div className='section__content col-12 col-md-8'>
							<div className='section__title weight__bold text-uppercase text__beta title-border__alfa'>
								<h2>{list[0].title}</h2>
							</div>
							<div className='section__subtitle color-strong__beta text__eta weight__light weight-strong__bold '>
								{ReactHtmlParser(list[0].summary_description)}
							</div>
							<div className='section__prelude text__beta weight__regular '>
								{ReactHtmlParser(list[0].short_description)}
							</div>
							<div className='section__text text__zeta text-h3__beta color-h3__beta weight-h3__bold list__alfa'>
								{ReactHtmlParser(list[0].full_description)}
							</div>
						</div>
						<figure className='section__image col-12 col-md-4'>
							<img src={list[0].thumbnail} alt='' />
						</figure>
					</div>
				</div>
			</section>
			<section
				className='section section-our-innovations-article1'
				data-section='item'
				ref={section2}
				id={list[1].slug}
			>
				<div className='section__container container__alfa color__psi'>
					<div className='section__row row flex-column-reverse flex-md-row'>
						<figure className='section__image col-12 col-md-4 col-lg-5'>
							<img src={list[1].thumbnail} alt='' />
						</figure>
						<div className='section__content  col-12 col-md-8 col-lg-7'>
							<header className='section__title weight__bold text-uppercase text__beta title-border__alfa'>
								<h2>{list[1].title}</h2>
							</header>
							<div className='section__subtitle color-strong__beta text__eta weight__light weight-strong__bold '>
								{ReactHtmlParser(list[1].summary_description)}
							</div>
							<div className='section__prelude text__beta weight__regular '>
								{ReactHtmlParser(list[1].short_description)}
							</div>
							<div className='section__text text__zeta text-h3__beta weight-h3__bold list__alfa'>
								{ReactHtmlParser(list[1].full_description)}
							</div>
						</div>
					</div>
				</div>
			</section>
			<section
				className='section section-our-innovations-article2'
				data-section='item'
				ref={section3}
				id={list[2].slug}
			>
				{/* <span className='section__bg'>
					<img src={imageInnovations2Bg} alt='' />
				</span> */}
				<div className='section__container container__alfa color__psi'>
					<div className='section__row row'>
						<div className='section__content offset-0 offset-lg-1 col-12 col-md-8 col-lg-7'>
							<header className='section__title weight__bold text-uppercase text__beta title-border__alfa'>
								<h2>{list[2].title}</h2>
							</header>
							<div className='section__subtitle color-strong__beta text__eta weight__light weight-strong__bold '>
								{ReactHtmlParser(list[2].summary_description)}
							</div>
							<div className='section__prelude text__beta weight__regular '>
								{ReactHtmlParser(list[2].short_description)}
							</div>
							<div className='section__text text__zeta text-h3__beta weight-h3__bold list__alfa'>
								{ReactHtmlParser(list[2].full_description)}
							</div>
						</div>
						<figure className='section__image col-12 col-md-4'>
							<img src={list[2].thumbnail} alt='' />
						</figure>
					</div>
				</div>
			</section>
			<WebFooter />
		</div>
	);
};

export default Page;
