import React, {LazyExoticComponent, useRef, Suspense, lazy, useEffect, useState} from 'react';
import classNames from 'helpers/classNames';
import gsapHorizontal from 'components/ComponentGsapHorizontal';
import {Link, useParams} from 'react-router-dom';

// const cx = classNames(styles);

// Headers
import logo from 'assets/dnr-logo.svg';
import user from 'assets/icon__user.svg';
import WebHeader from 'components/ComponentWebHeader';
import 'styles/layout/SectionFaq.scss';

// Hero
import imageOfferSingleBg from 'assets/bg__offer-page.jpg';
import 'styles/layout/SectionOfferSingle.scss';
import imageOfferList0 from 'assets/icon__laptop.svg';
import ReactHtmlParser from 'react-html-parser';
import list from 'temp/content/offer.ts';
import Error from 'components/Error.jsx';

// Innovations
import SectionInnovationsShort from 'components/ComponentInnovationsShort';

// Footer
import WebFooter from 'components/ComponentWebFooter';

const Page = ({page, url}) => {
	const offerWrapper = useRef(null);
	const [activeIndex, setActiveIndex] = useState('');
	const [currentContent, setCurrentContent] = useState(undefined);
	const [openQuestions, setOpenQuestions] = useState([]);
	const [width, setWidth] = useState(window.innerWidth);
	const {slug} = useParams();
	const [isError, setIsError] = useState(false);

	window.addEventListener('resize', () => {
		setWidth(window.innerWidth);
	});

	const toggleAction = (index) => {
		// console.log(openQuestions)
		if (openQuestions.includes(index)) {
			const newOpenQuestions = openQuestions.filter((item) => {
				return item !== index;
			});

			setOpenQuestions(newOpenQuestions);
		} else {
			let newArray = [...openQuestions];
			newArray.push(index);
			setOpenQuestions(newArray);
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);

		if (slug) {
			const items = list.filter((item) => item.slug === slug);

			if (items.length < 1) {
				setIsError(true);
			} else {
			}

			setCurrentContent(getContent(slug));
			setActiveIndex(slug);
			toggleAction(slug);
		} else if (list.length > 0) {
			setCurrentContent(getContent(list[0].slug));
			setActiveIndex(list[0].slug);
			toggleAction(list[0].slug);
		}

		return () => {};
	}, []);

	const getContent = (slug) => {
		const items = list.filter((item) => item.slug === slug);

		if (items.length > 0)
			return (
				<article
					className='content__item isActive'
					data-offer='item'
					data-offer-index={1}
					data-offer-active='true'
				>
					<header className='item__header weight__bold'>
						<h3>{items[0].title}</h3>
					</header>
					<div className='item__content weight__light weight-strong__regular  '>
						{ReactHtmlParser(items[0].full_description)}
					</div>
				</article>
			);
	};

	const handleOfferClick = async (clickedElement, index) => {
		let alreadyExist = false;
		setActiveIndex(index);
		setCurrentContent(getContent(index));

		// Check if there is already loaded content for this element
		// if (offerWrapper.current) {
		// 	const allElements = offerWrapper.current.querySelectorAll(`[data-offer='item']`);

		// 	if (allElements.length > 0) {
		// 		allElements.offerList.forEach((item) => {
		// 			if (item.getAttribute(`data-offer-index`) === index) {
		// 				item.setAttribute(`data-offer-active`, `true`);
		// 				item.classList.add('isActive');
		// 				alreadyExist = true;
		// 			} else {
		// 				item.setAttribute(`data-offer-activ`, `false`);
		// 				item.classList.remove('isActive');
		// 			}
		// 		});
		// 	}

		// 	if (!alreadyExist) {
		// 		// const contentData = await fetch("components/ajaxOfferItem.php", {
		// 		//     method: 'get',
		// 		// })
		// 		//     .then(res => res.text())
		// 		//     .then(data => {
		// 		//         return data;
		// 		//     })

		// 		// offerWrapper.current.innerHTML = '';
		// 		offerWrapper.current.insertAdjacentHTML('beforeend', contentData);
		// 	}
		// }
	};

	if (isError) {
		return <Error />;
	}

	return (
		<div>
			<WebHeader pageInfo={page} />
			{width >= 768 ? (
				<section className='section section-offer-single' data-section='item'>
					{/* <span className='section__bg'>
						<img src={imageOfferSingleBg} alt='' />
					</span> */}
					<div className='section__container color__psi'>
						<header className='section__header text__eta weight__bold'>
							<h1>Oferta</h1>
						</header>
						<div className='section__content'>
							<div className='section__row'>
								<nav className='section__col section__col--nav'>
									<ul className='nav__list'>
										{list.map((item, index) => (
											<li className='nav__item' key={index}>
												<button
													onClick={(e) => {
														handleOfferClick(e.currentTarget, item.slug);
													}}
													className={`nav__link ${
														item.slug === activeIndex ? 'isActive' : ''
													}`}
												>
													<span className='icon'>
														<img src={item.thumbnail} alt='' />
													</span>
													<span className='text text__beta weight__regular'>
														{item.title}
													</span>
												</button>
											</li>
										))}
									</ul>
								</nav>
								<div className='section__col section__col--content text__beta' ref={offerWrapper}>
									{currentContent}
								</div>
							</div>
						</div>
					</div>
				</section>
			) : (
				<section className='section section-faq' data-section='item'>
					{/* <span className='section__bg'>
						<img src={imageOfferSingleBg} alt='' />
					</span> */}
					<div className='section__container color__psi'>
						<header className='section__header text__eta weight__bold text-center'>
							<h1>Oferta</h1>
						</header>
						<div className='section__content'>
							<ul className='section__list'>
								{list.map((item, index) => (
									<li className='section__item' key={index}>
										<div
											className={`item__link text__beta weight__regular ${
												openQuestions.includes(item.slug) ? 'isActive' : ''
											}`}
										>
											<button
												className='link__button text__beta weight__regular'
												onClick={() => {
													toggleAction(item.slug);
												}}
											>
												<span className='item__bg'></span>
												<div className='item__top'>
													<span className='counter weight__black'>{index + 1}.</span>
													<span className='text'>{item.title}</span>
													<span className='arrow'></span>
												</div>
											</button>
											<div className='item__content'>
												{ReactHtmlParser(item.full_description)}
											</div>
										</div>
									</li>
								))}
							</ul>
						</div>
					</div>
				</section>
			)}
			<SectionInnovationsShort />
			<WebFooter />
		</div>
	);
};

export default Page;
